import React from 'react';
import { signInWithRedirect } from 'aws-amplify/auth';
import images from '../../assets/images';
import { useTranslation } from 'react-i18next';

const SocialMediaLoginForm = () => {
  const { t } = useTranslation();

  const thirdPartyLogin = (provider) => {
    localStorage.setItem('is_federate', 'true');
    signInWithRedirect({ provider });
  };

  return (
    <div className="pt-3  pb-3">
      <p className="login-link text-center">
        ------------------------------------ {t('route.or')} -----------------------------------
      </p>
      <div className="social-media form-row justify-content-center">
        <div className="col-12">
          <a
            href="#"
            title={t<string>('login.label.google')}
            onClick={() => thirdPartyLogin('Google')}
            className="google-btn d-flex align-items-center justify-content-center mb-2"
          >
            <img src={images.googleLogoPNG} alt="" />
            {t('login.label.google')}
          </a>
        </div>
        <div className="col-12">
          <a
            href="#"
            title={t<string>('login.label.facebook')}
            onClick={() => thirdPartyLogin('Facebook')}
            className="facebook-btn d-flex align-items-center justify-content-center mb-2"
          >
            <img src={images.facebookLogoSVG} alt="" />
            {t('login.label.facebook')}
          </a>
        </div>
        <div className="col-12">
          <a
            href="#"
            title={t<string>('login.label.apple')}
            onClick={() => thirdPartyLogin('Apple')}
            className="ios-btn d-flex align-items-center justify-content-center mb-2"
          >
            <img src={images.iosSVG} alt="" />
            {t('login.label.apple')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default SocialMediaLoginForm;
