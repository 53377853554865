import React, { useEffect, useState } from 'react';
import { Amplify } from 'aws-amplify';
import { getCurrentUser, fetchAuthSession, AuthSession, AuthUser, fetchUserAttributes } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import * as AppActionCreators from './store/actions/app.Actions';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { AppState } from './store/state/app.state';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { MainLayout } from './layout';
import LoginPage from './pages/login/LoginPage';
import { toast, ToastContainer } from 'react-toastify';
import amplifyConfig from './utils/amplifyConfig';
import 'react-toastify/dist/ReactToastify.css';
import FAQPage from './pages/faq/FAQPage';
import FleetFAQPage from './pages/faq/FleetFAQPage';
import PremiumSubscriptionPage from './pages/premium-subscription/PremiumSubscriptionPage';
import { getAPIKey } from './services/login/login-service';
import { Common } from './utils/constants';
import { useTranslation } from 'react-i18next';
import { getConfigurationDetail, getProfile } from './services/user/user-service';
import { StandardResponse } from './models/standard-response';
import { IUserData as IUserProfileData } from './models/profile';
import { checkEmailAlreadyExists, signup } from './services/login/loginApi-service';
import TermsAndConditionListing from './pages/terms-conditions/TermsAndConditionListing';
import CookieConsent from 'react-cookie-consent';
import { IGetConfigurationData } from './models/profile/index';
import OpenSource from './pages/open-source/OpenSource';
import { ConfirmationBox } from './components/common/ConfirmationBox';
import PaymentPage from './pages/payment/PaymentPage';
import { store } from './store';
import AboutUs from './pages/about-us/AboutUs';
import Referral from './pages/referral/Referral';
import PartnerPromotionPage from './pages/partnerPromotion/PartnerPromotionPage';
import DownloadVATReceipt from './pages/downloadVATReceipt/DownloadVATReceipt';
import { companyNameReplace } from './utils';
import { ISupportedLanguage } from './store/state/language.state';
import { IModelState } from './types/stateTypes';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { IListenerData, IObjectRequest } from './models/login';
import DOMPurify from 'isomorphic-dompurify';

const RefundRequestGrid = React.lazy(() => import('./pages/refund-request/RefundRequestGrid'));
const ProfilePage = React.lazy(() => import('./pages/profile/profilePage'));
const HistoryPage = React.lazy(() => import('./pages/history/HistoryPage'));
const Payment = React.lazy(() => import('./pages/payment/Payment'));
const LoyaltyPointGrid = React.lazy(() => import('./pages/loyalty-points/LoyaltyPointGrid'));
const RFIDCardsPage = React.lazy(() => import('./pages/rfid-cards/RFIDCardsPage'));
const AcceptedTermsConditions = React.lazy(() => import('./pages/terms-conditions/AcceptedTermsAndConditions'));

Amplify.configure(amplifyConfig);

const queryStringLang = new URLSearchParams(window.location.search);
const languageInfo = store?.getState()?.LanguageInfo?.supportedLanguages;
const changelanguage = languageInfo
  ? languageInfo?.filter(
      (item: ISupportedLanguage) =>
        item?.code === queryStringLang.get('langCode') || item?.code === queryStringLang.get('language'),
    )[0]
  : {
      code: queryStringLang.get('langCode') || queryStringLang.get('language'),
    };

const App = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const actions = bindActionCreators(AppActionCreators, dispatch);

  const userData = useSelector((state: AppState) => state.UserData);
  const navigate = useNavigate();

  const location = useLocation();

  const storeState = store.getState();
  const [isReady, setIsReady] = useState<boolean>(
    location.pathname.includes('/partner-promotion') || location.pathname.includes('/payment/initiate'),
  );
  const [cookiePolicyURL, setCookiePolicyURL] = useState<string>('');
  const [modal, setModal] = useState<IModelState>({
    showModel: false,
    callBack: null,
    header: '',
    body: '',
  });
  const [showCookies, setShowCookies] = useState<boolean>(false);

  useEffect(() => {
    if (localStorage.getItem(Common.OauthFedratedSignIn)) {
      toast.error(t('login.toast.cancelled'));
      localStorage.removeItem(Common.OauthFedratedSignIn);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (location.pathname.includes('/download-receipt')) {
      sessionStorage.setItem('vat_receipt_path', location.pathname);
    }
  }, [location.pathname]);

  const fetchData = async () => {
    if (!(location.pathname.includes('/partner-promotion') || location.pathname.includes('/payment/initiate'))) {
      Hub.listen('auth', listener);
      await tryAutoSignIn();
      await getCookiePolicyURL();
    }
    await getLanguageThroughQueryparams();
    const config = await getAPIKey({ code: 'COMPANY_BRAND_NAME' });
    actions.setCompanyName(config.data[0].value);
  };

  const getLanguageThroughQueryparams = async () => {
    if (queryStringLang.get('langCode') || queryStringLang.get('language')) {
      actions.changeLanguage(changelanguage);
    }
  };

  const listener = async (data: IListenerData) => {
    switch (data.payload.event) {
      case 'signedIn': {
        const user: AuthUser = await getCurrentUser();
        const userSession: AuthSession = await fetchAuthSession();
        const userAttributes = await fetchUserAttributes();

        const federated_account_type = userAttributes?.identities
          ? JSON.parse(userAttributes.identities)[0].providerName
          : null;
        try {
          const name = userAttributes && userAttributes.name && userAttributes.name.split(' ');
          const objRequest = {
            first_name: name && name[0] ? name[0] : ' ',
            last_name: name && name[1] ? name[1] : ' ',
            email: userAttributes && userAttributes.email,
            provider_key: userAttributes && userAttributes.sub,
            federated_account_type,
            is_federated: federated_account_type !== null,
            language_preference_id: storeState?.UserData?.language?.id || storeState?.LanguageInfo?.defaultLanguage?.id,
          };

          if (federated_account_type !== null) {
            checkEmailAlreadyExistsOrNot(objRequest, user);
          } else {
            await signup(objRequest);
          }
        } catch (_err) {}

        if (userSession && userSession.tokens?.idToken?.payload.identities && federated_account_type === null) {
          await getProfile()
            .then((res: StandardResponse<IUserProfileData>) => {
              if (res && res.Data && res.Data.status && res.Data.status.code === Common.UserStatus.CANCELLED) {
                actions
                  .logout()(dispatch)
                  .then(() => {
                    localStorage.setItem(Common.OauthFedratedSignIn, 'true');
                    setIsReady(true);
                  });
              } else {
                actions.login({ ...user, ...userSession });
                actions.userDetail(res.Data);
                actions.setCurrencyDetails(res?.Data?.default_currency);
                setIsReady(true);

                if (!location?.pathname?.includes('/download-receipt')) {
                  if (res && res.Data.terms_and_condition_required) {
                    const referralCode = localStorage.getItem('referralCode');

                    navigate('/TermsAndCondition', {
                      state: { referralCode: referralCode },
                    });
                  } else if (
                    res &&
                    res.Data.is_profile_verified === false &&
                    !location?.pathname?.includes('/terms-and-conditions')
                  ) {
                    if (location.pathname !== Common.PayamentPage && location.pathname !== Common.PayamentSecure) {
                      navigate('/profile?redirect=true');
                    }
                  } else if (
                    res &&
                    res.Data.is_profile_verified === true &&
                    !location?.pathname?.includes('/terms-and-conditions')
                  ) {
                    if (res && res.Data.payment_type === null) {
                      if (location.pathname !== Common.PayamentPage && location.pathname !== Common.PayamentSecure) {
                        navigate('/payment');
                      }
                    } else {
                      navigate('/');
                    }
                  }
                }
              }
            })
            .catch((e) => {
              console.error(e);
            });
        }
        break;
      }
      case 'signedIn_failure':
        console.warn('user sign in failed');
        break;
      case 'configured':
        console.warn('the Auth module is configured');
        break;
      default:
        console.warn('Nothing Happened');
    }
  };

  const tryAutoSignIn = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const isFederate = localStorage.getItem('is_federate');
    const myReferralCode = urlParams.get('code');
    if (myReferralCode !== null && myReferralCode !== '' && !myReferralCode.includes('-')) {
      localStorage.setItem('referralCode', myReferralCode);
    }

    try {
      const currentUser: AuthUser = await getCurrentUser();
      const userSession: AuthSession = await fetchAuthSession();

      if (currentUser && userSession && isFederate !== 'true') {
        await getProfile()
          .then((userDetail: StandardResponse<IUserProfileData>) => {
            if (
              userDetail &&
              userDetail.Data &&
              userDetail.Data.status &&
              userDetail.Data.status.code === Common.UserStatus.CANCELLED
            ) {
              actions
                .logout()(dispatch)
                .then(() => {
                  localStorage.setItem(Common.OauthFedratedSignIn, 'true');
                  setIsReady(true);
                });
            } else {
              if (queryStringLang.get('langCode') || queryStringLang.get('language')) {
                getLanguageThroughQueryparams();
              } else {
                actions.changeLanguage(userDetail?.Data?.language_preference);
              }
              actions.login({ ...currentUser, ...userSession });
              actions.userDetail(userDetail.Data);
              actions.setCurrencyDetails(userDetail?.Data?.default_currency);
              setIsReady(true);
              if (!location?.pathname?.includes('/download-receipt')) {
                if (userDetail && userDetail.Data.terms_and_condition_required) {
                  const referralCode = localStorage.getItem('referralCode');

                  navigate('/TermsAndCondition', {
                    state: { referralCode: referralCode },
                  });
                } else if (
                  userDetail &&
                  userDetail.Data.is_profile_verified === false &&
                  !location?.pathname?.includes('/terms-and-conditions')
                ) {
                  if (location.pathname !== Common.PayamentPage && location.pathname !== Common.PayamentSecure) {
                    navigate('/profile?redirect=true');
                  }
                } else if (
                  userDetail &&
                  userDetail.Data.is_profile_verified === true &&
                  !location?.pathname?.includes('/terms-and-conditions')
                ) {
                  if (userDetail && userDetail.Data.payment_type === null) {
                    if (location.pathname !== Common.PayamentPage && location.pathname !== Common.PayamentSecure) {
                      navigate('/payment');
                    }
                  }
                }
              }
            }
          })
          .catch(() => {
            if (!(myReferralCode && window.location.pathname === '/')) {
              actions.logout();
              toast.error(t('login.toast.error'));
            }
            setIsReady(true);
          });
      } else {
        localStorage.removeItem('is_federate');
      }
    } catch (_error) {
      if (!(myReferralCode && window.location.pathname === '/')) {
        actions.logout();
      }
      setIsReady(true);
    }
  };

  const getCookiePolicyURL = async () => {
    await getConfigurationDetail({
      code: Common.ConfigurationKey.COOKIE_POLICY_URL,
    })
      .then((res: IGetConfigurationData) => {
        setCookiePolicyURL(res.data[0]?.value);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.message) {
          toast.error(e.response.data.message);
        } else {
          toast.error(e.toString());
        }
      });
  };

  const checkEmailAlreadyExistsOrNot = async (objRequest: IObjectRequest, user: AuthUser) => {
    objRequest.federated_account_type =
      objRequest.federated_account_type === null || objRequest.federated_account_type === undefined
        ? ''
        : objRequest.federated_account_type;
    const input = {
      email: objRequest.email,
      federated_account_type: objRequest.federated_account_type,
    };
    await checkEmailAlreadyExists(input)
      .then(async (_res) => {
        loginFederatedAccount(objRequest, user);
      })
      .catch((m) => {
        if (
          m.response &&
          m.response.status &&
          m.response.status === 409 &&
          m.response.data &&
          typeof m.response.data === 'object'
        ) {
          if (Object.keys(m.response.data).length === 0) {
            let modalMessage = companyNameReplace(t('signup.label.alreadyRegisteredWithNormalAcc'));
            const emailString = `<span class='themeTextColor'>${String(objRequest.email).toString()}</span>`;
            modalMessage = modalMessage.replace('##EMAIL##', emailString);

            handleModal(modalMessage, companyNameReplace(t('signup.label.instavolt')), () =>
              loginFederatedAccount(objRequest, user),
            );
          } else {
            const existingEmails = m.response.data.data ? m.response.data.data : null;
            if (!_.isEmpty(existingEmails)) {
              let modalMessage = '';
              modalMessage = companyNameReplace(t('signup.label.modalMessage'));
              const emailString = `<span class='themeTextColor'>${String(objRequest.email).toString()}</span>`;
              modalMessage = modalMessage.replace('##EMAIL##', emailString);
              let accounts = '';
              const existingEmailsLength = existingEmails.length;
              existingEmails.forEach((account, index: number) => {
                if (account.federated_account_type !== null) {
                  if (existingEmailsLength === 1 || existingEmailsLength === index + 1) {
                    accounts = `${accounts}<span class='themeTextColor'>${account.federated_account_type.toString()}</span>`;
                  } else {
                    accounts = `${accounts}<span class='themeTextColor'>${account.federated_account_type.toString()}</span>, `;
                  }
                } else {
                  if (existingEmailsLength === 1 || existingEmailsLength === index + 1) {
                    accounts = `${accounts}<span class='themeTextColor'>${companyNameReplace(t('signup.label.instavoltUserAccount'))}</span>`;
                  } else {
                    accounts = `${accounts}<span class='themeTextColor'>${companyNameReplace(t('signup.label.instavoltUserAccount'))}</span>, `;
                  }
                }
              });
              modalMessage = modalMessage.replace('##FEDERATEDACCOUNT##', accounts);
              handleModal(modalMessage, companyNameReplace(t('signup.label.instavolt')), () =>
                loginFederatedAccount(objRequest, user),
              );
            } else {
              toast.error(t('login.toast.error'));
            }
          }
        } else if (m.response && m.response.data && m.response.data.message) {
          sessionStorage.setItem('error_message', m.response.data.message);
          actions.logout();
        } else {
          toast.error(m.toString());
        }
      })
      .finally();
  };

  const loginFederatedAccount = async (objRequest: IObjectRequest, user: AuthUser) => {
    const downloadVATRedirect = sessionStorage.getItem('vat_receipt_path');
    const userSession = await fetchAuthSession();
    try {
      await signup(objRequest);
    } catch (_err) {}
    await getProfile()
      .then((res: StandardResponse<IUserProfileData>) => {
        if (res && res.Data && res.Data.status && res.Data.status.code === Common.UserStatus.CANCELLED) {
          actions
            .logout()(dispatch)
            .then(() => {
              localStorage.setItem(Common.OauthFedratedSignIn, 'true');
              setIsReady(true);
            });
        } else {
          if (queryStringLang.get('langCode') || queryStringLang.get('language')) {
            getLanguageThroughQueryparams();
          } else {
            actions.changeLanguage(res?.Data?.language_preference);
          }
          actions.login({ ...user, ...userSession });
          actions.userDetail(res.Data);
          actions.setCurrencyDetails(res?.Data?.default_currency);
          setIsReady(true);
          if (downloadVATRedirect && downloadVATRedirect !== '') {
            sessionStorage.removeItem('vat_receipt_path');
            navigate(downloadVATRedirect);
          } else if (res && res.Data.terms_and_condition_required) {
            const referralCode = localStorage.getItem('referralCode');
            navigate('/TermsAndCondition', {
              state: { referralCode: referralCode },
            });
          } else if (
            res &&
            res.Data.is_profile_verified === false &&
            !location?.pathname?.includes('/terms-and-conditions')
          ) {
            if (location.pathname !== Common.PayamentPage && location.pathname !== Common.PayamentSecure) {
              navigate('/profile?redirect=true');
            }
          } else if (
            res &&
            res.Data.is_profile_verified === true &&
            !location?.pathname?.includes('/terms-and-conditions')
          ) {
            if (!localStorage.getItem(`${Common.MARKETING_CONSENT}-${res.Data.id}`)) {
              navigate('/profile');
            } else if (res && res.Data.payment_type === null) {
              if (location.pathname !== Common.PayamentPage && location.pathname !== Common.PayamentSecure) {
                navigate('/payment');
              }
            } else {
              navigate('/');
            }
          }
        }
      })
      .catch((e) => {
        console.error(e);
      });
    setModal((prevModal) => ({
      ...prevModal,
      showModel: false,
      header: '',
      body: '',
      callBack: null,
    }));
  };

  const handleModal = (body: string, header: string, callBack: () => void) => {
    setModal((prevModal) => ({
      ...prevModal,
      body: body,
      header: header,
      callBack: callBack,
      showModel: !prevModal.showModel,
    }));
  };

  const closeModal = () => {
    setModal((prevModal) => ({
      ...prevModal,
      body: '',
      header: '',
      callBack: null,
      showModel: false,
    }));

    actions
      .logout()(dispatch)
      .then(() => {
        localStorage.setItem('is_federated_login', 'true');
        navigate('/login');
      });
  };

  const handleLanguageAndError = () => {
    if (!showCookies) {
      setShowCookies(true);
    }
  };

  const checkAuthenticatedUser = (component) => {
    if (
      !(userData && userData.authConfigs && userData.authConfigs.accessToken) ||
      userData.authConfigs.accessToken === ''
    ) {
      return <Navigate replace to="/login" />;
    } else {
      return component;
    }
  };

  const checkNotAuthenticatedUser = (isSignUp: boolean) => {
    if (userData === null || (userData && userData.language && !userData.authConfigs)) {
      if (isSignUp) {
        return <LoginPage isSignUp={isSignUp} handleLanguageAndError={handleLanguageAndError} key={location.key} />;
      } else {
        return <LoginPage handleLanguageAndError={handleLanguageAndError} key={location.key} />;
      }
    } else if (userData.terms_and_condition_required) {
      return <Navigate replace to="/TermsAndCondition" />;
    } else if (userData.is_profile_verified === false) {
      return <Navigate replace to="/profile?redirect=true" />;
    } else if (userData.payment_type === null) {
      return <Navigate replace to="/payment" />;
    } else {
      return <Navigate replace to="/" />;
    }
  };

  return (
    <>
      {isReady && (
        <Routes>
          <Route path="/payment/initiate/:platform?/:version?" element={<PaymentPage />} />
          <Route
            path="/FAQ"
            element={
              <FAQPage
                setNumberFormat={AppActionCreators.setNumberFormat}
                setCurrencyFormat={AppActionCreators.setCurrencyFormat}
              />
            }
          />
          <Route path="/fleet-faq" element={<FleetFAQPage />} />
          <Route path="/open-source" element={<OpenSource />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/premium-subscription" element={<PremiumSubscriptionPage />} />

          <Route path="/referral/:id?" element={<Referral />} />
          <Route path="/partner-promotion/:id/:driver_id/:session_id?" element={<PartnerPromotionPage />} />
          <Route path="/login" element={checkNotAuthenticatedUser(false)} />
          <Route path="/sign-up" element={checkNotAuthenticatedUser(true)} />

          <Route path="/TermsAndCondition" element={checkAuthenticatedUser(<TermsAndConditionListing />)} />

          <Route
            path="/download-receipt/:session_id?/:session_primary_id?"
            element={checkAuthenticatedUser(<DownloadVATReceipt />)}
          />
          <Route element={<MainLayout logout={actions.logout} />}>
            <Route path="/" element={checkAuthenticatedUser(<HistoryPage />)} />
            <Route path="/Profile" element={checkAuthenticatedUser(<ProfilePage />)} />
            <Route path="/terms-and-conditions" element={checkAuthenticatedUser(<AcceptedTermsConditions />)} />

            <Route path="/payment" element={checkAuthenticatedUser(<Payment />)} />
            <Route path="/rewards" element={checkAuthenticatedUser(<LoyaltyPointGrid />)} />
            <Route path="/refundRequests" element={checkAuthenticatedUser(<RefundRequestGrid />)} />
            <Route path="/rfid-cards" element={checkAuthenticatedUser(<RFIDCardsPage />)} />
          </Route>
          {/* <Redirect to="/login" /> */}
          <Route path="/" element={<Navigate replace to="/login" />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      )}
      <ToastContainer />
      {!(
        location.pathname.toLowerCase().includes('faq') ||
        location.pathname.toLowerCase().includes('open-source') ||
        location.pathname.toLowerCase().includes('premium-subscription') ||
        location.pathname.toLowerCase().includes('partner-promotion') ||
        location.pathname.toLowerCase().includes('/payment/initiate') ||
        location.pathname.toLowerCase().includes('/terms-and-conditions') ||
        location.pathname.toLowerCase().includes('/about-us')
      ) && (
        <CookieConsent buttonText={t('cookiePolicy.iAccept')} expires={30}>
          {t('cookiePolicy.text')}{' '}
          <a href={cookiePolicyURL} target="_blank" rel="noopener noreferrer" className="cookiePolicy">
            {t('cookiePolicy.action')}
          </a>
        </CookieConsent>
      )}
      <ConfirmationBox
        onCancel={closeModal}
        onConfirm={modal.callBack}
        header={modal.header}
        bodyMessage={<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(modal.body) }}></div>}
        Message={modal.body}
        body={modal.body}
        showModel={modal.showModel}
        closeModel={closeModal}
      />
    </>
  );
};

export default App;
