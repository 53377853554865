import BaseService from '../base-service';
import { StandardResponse, createStandardResponse } from './../../models/standard-response';
import {
  IPaymentCredential,
  IGetCardDetail,
  IEnrollment,
  ICardList,
  IBalanceDetails,
  IMaxPrepaymentDetail,
  IRefundRequestList,
  IDefaultPSP,
  IChargingSession,
  ISavePaymentType,
  IGetPaymentCredential,
  IInputCardData,
  IPayNow,
  IEditCardData,
  IGetAPIKey,
  IGetCardList,
  ITransactionRequestData,
  IRefundRequestInput,
  IPaymentInvoice,
  IPaymentInvoiceInput,
  ITransctionResponse,
  IRejectedPromise,
} from 'models/payment';
import { IGetConfigurationData } from 'models/profile';
import { ICreditCharityData, IRefundRequestForm, IWithdrawAmountForm } from 'types/stateTypes';
import { Common } from 'utils/constants';

export const savePaymentType = async (data: ISavePaymentType): Promise<StandardResponse<number>> => {
  try {
    const res = await BaseService({
      url: `/driver/payment-type`,
      method: 'put',
      data,
    });
    return createStandardResponse<number>(res.status, res.data.data, res.data.message, null);
  } catch (e: unknown) {
    return Promise.reject((e as IRejectedPromise).response.data);
  }
};

export const getPaymentCredential = async (
  data: IGetPaymentCredential,
): Promise<StandardResponse<IPaymentCredential>> => {
  try {
    const res = await BaseService({
      url: `/driver/payment-credentials`,
      method: 'post',
      data,
    });
    return createStandardResponse<IPaymentCredential>(res.status, res.data.data, res.statusText, null);
  } catch (e: unknown) {
    return Promise.reject((e as IRejectedPromise).response.data);
  }
};

export const CheckEnrollment = async (data): Promise<StandardResponse<IEnrollment>> => {
  try {
    const res = await BaseService({
      url: `/driver/payment/enrollment-check`,
      method: 'post',
      data,
    });
    return createStandardResponse<IEnrollment>(res.status, res.data.data, res.statusText, null);
  } catch (e: unknown) {
    return Promise.reject((e as IRejectedPromise).response.data);
  }
};

export const getWalletAmount = async (): Promise<StandardResponse<number>> => {
  try {
    const res = await BaseService({
      url: `/driver/payment/wallet-balance/`,
      method: 'get',
    });
    return createStandardResponse<number>(res.status, res.data.data, res.statusText, null);
  } catch (e: unknown) {
    return Promise.reject((e as IRejectedPromise).response.data);
  }
};

export const SaveCardData = async (data: IInputCardData): Promise<StandardResponse<number>> => {
  try {
    const res = await BaseService({
      url: '/driver/save-card',
      method: 'post',
      data,
    });
    return createStandardResponse<number>(res.status, res.data.data, res.data.message, null);
  } catch (e: unknown) {
    return Promise.reject((e as IRejectedPromise).response.data);
  }
};

export const GetCardData = async (): Promise<StandardResponse<IGetCardDetail>> => {
  try {
    const res = await BaseService({
      url: `/driver/get-card-details`,
      method: 'get',
    });
    return createStandardResponse<IGetCardDetail>(res.status, res.data.data, res.statusText, null);
  } catch (e: unknown) {
    return Promise.reject((e as IRejectedPromise).response.data);
  }
};

export const DeletCardData = async (): Promise<StandardResponse<number>> => {
  try {
    const res = await BaseService({
      url: `/driver/delete-card-details`,
      method: 'delete',
    });
    return createStandardResponse<number>(res.status, res.data.data, res.data.message, null);
  } catch (e: unknown) {
    return Promise.reject((e as IRejectedPromise).response.data);
  }
};

export const payNow = async (data: IPayNow): Promise<StandardResponse<number>> => {
  try {
    const res = await BaseService({
      url: `/driver/payment/charge-amount`,
      method: 'post',
      data,
    });
    return createStandardResponse<number>(res.status, res.data.data, res.data.message, null);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const ReplaceCardData = async (data): Promise<StandardResponse<number>> => {
  try {
    const res = await BaseService({
      url: '/driver/replace-card',
      method: 'put',
      data,
    });
    return createStandardResponse<number>(res.status, res.data.data, res.data.message, null);
  } catch (e: unknown) {
    return Promise.reject((e as IRejectedPromise).response.data);
  }
};

export const EditCardData = async (data: IEditCardData): Promise<StandardResponse<Array<number>>> => {
  try {
    const res = await BaseService({
      url: '/driver/update-card',
      method: 'put',
      data,
    });
    return createStandardResponse<Array<number>>(res.status, res.data.data, res.data.message, null);
  } catch (e: unknown) {
    return Promise.reject((e as IRejectedPromise).response.data);
  }
};

export const getAPIKey = async (data: IGetAPIKey): Promise<IGetConfigurationData> => {
  try {
    const res = await BaseService({
      url: '/driver/public/configuration/search',
      method: 'POST',
      data: { ...data, byPassAuth: true },
    });
    return Promise.resolve(res.data);
  } catch (e) {
    return Promise.reject(e);
  }
};
export const getConfigurationKey = async (data: IGetAPIKey): Promise<IGetConfigurationData> => {
  try {
    const res = await BaseService({
      url: '/driver/private/configuration/search',
      method: 'POST',
      data: { ...data },
    });
    return Promise.resolve(res.data);
  } catch (e) {
    return Promise.reject(e);
  }
};
export const getPublicConfig = async (data: IGetAPIKey): Promise<IGetConfigurationData> => {
  try {
    const res = await BaseService({
      url: '/driver/public/configuration/search',
      method: 'POST',
      data: { ...data, byPassAuth: true },
    });
    return Promise.resolve(res.data);
  } catch (e) {
    return Promise.reject(e);
  }
};
export const getCardList = async (data: IGetCardList): Promise<StandardResponse<ICardList>> => {
  try {
    const res = await BaseService({
      url: `/driver/refund/cards`,
      method: 'POST',
      data,
    });
    return createStandardResponse<ICardList>(res.status, res.data.data, res.data.message, null);
  } catch (e) {
    return Promise.reject(e);
  }
};
export const getBalanceDetails = async (): Promise<StandardResponse<IBalanceDetails>> => {
  try {
    const res = await BaseService({
      url: '/driver/balance-details',
      method: 'GET',
    });
    return createStandardResponse<IBalanceDetails>(res.status, res.data.data, res.data.message, null);
  } catch (e) {
    return Promise.reject(e);
  }
};
export const withdrawMoney = async (data): Promise<StandardResponse<IEnrollment>> => {
  try {
    const res = await BaseService({
      url: '/driver/payment/withdraw-money',
      method: 'POST',
      data,
    });
    return createStandardResponse<IEnrollment>(res.status, res.data.data, res.data.message, null);
  } catch (e) {
    return Promise.reject(e);
  }
};
export const getMaxPrePaymentBalance = async (
  data: IGetAPIKey,
): Promise<StandardResponse<Array<IMaxPrepaymentDetail>>> => {
  try {
    const res = await BaseService({
      url: '/driver/public/configuration/search',
      method: 'POST',
      data: { ...data, byPassAuth: true },
    });
    return createStandardResponse<Array<IMaxPrepaymentDetail>>(res.status, res.data.data, res.data.message, null);
  } catch (e) {
    return Promise.reject(e);
  }
};
export const checkCardSecurity = async (data: IInputCardData): Promise<StandardResponse<IEnrollment>> => {
  try {
    const res = await BaseService({
      url: '/driver/payment/check-card-security',
      method: 'POST',
      data,
    });
    return createStandardResponse<IEnrollment>(res.status, res.data.data, res.data.message, null);
  } catch (e) {
    return Promise.reject(e);
  }
};
export const transaction = async (data: ITransactionRequestData): Promise<StandardResponse<ITransctionResponse>> => {
  try {
    const res = await BaseService({
      url: '/driver/payment/transaction',
      method: 'POST',
      data,
    });
    return createStandardResponse<ITransctionResponse>(res.status, res.data.data, res.data.message, null);
  } catch (e) {
    return Promise.reject(e);
  }
};
export const reFundRequest = async (
  data: IRefundRequestForm & IWithdrawAmountForm,
): Promise<StandardResponse<number>> => {
  try {
    const res = await BaseService({
      url: '/driver/refund-requests',
      method: 'POST',
      data,
    });
    return createStandardResponse<number>(res.status, res.data.data, res.data.message, null);
  } catch (e) {
    return Promise.reject(e);
  }
};
export const refundRequestList = async (data: IRefundRequestInput): Promise<StandardResponse<IRefundRequestList>> => {
  try {
    const res = await BaseService({
      url: '/driver/refund-requests/search',
      method: 'POST',
      data,
    });
    return createStandardResponse<IRefundRequestList>(res.status, res.data.data, res.data.message, null);
  } catch (e) {
    return Promise.reject(e);
  }
};
export const cancelRequest = async (id: number): Promise<StandardResponse<number>> => {
  try {
    const res = await BaseService({
      url: `/driver/refund-requests/${id}`,
      method: 'PUT',
    });
    return createStandardResponse<number>(res.status, res.data.data, res.data.message, null);
  } catch (e) {
    return Promise.reject(e);
  }
};
export const getPaymentUrl = async (pspName = Common.PaymentCode): Promise<string> => {
  try {
    const res = await BaseService({
      url: `/driver/payment/base-url/${pspName}`,
      method: 'GET',
    });
    return Promise.resolve(res.data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const createPaymentInvoice = async (data: IPaymentInvoiceInput): Promise<StandardResponse<IPaymentInvoice>> => {
  try {
    const res = await BaseService({
      url: '/driver/payment/create/invoice',
      method: 'POST',
      data,
    });
    return createStandardResponse<IPaymentInvoice>(res.status, res.data.data, res.data.message, null);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getTransactionDetails = async (invoiceID): Promise<StandardResponse<IBalanceDetails>> => {
  try {
    const res = await BaseService({
      url: `/driver/payment/invoice/${invoiceID}`,
      method: 'GET',
    });
    return createStandardResponse<IBalanceDetails>(res.status, res.data.data, res.data.message, null);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getDatatransTransactionDetails = async (invoiceID): Promise<StandardResponse<IBalanceDetails>> => {
  try {
    const res = await BaseService({
      url: `/driver/payment-datatrans/invoice/${invoiceID}`,
      method: 'GET',
    });
    return createStandardResponse<IBalanceDetails>(res.status, res.data.data, res.data.message, null);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getDefaultPSP = async (): Promise<StandardResponse<IDefaultPSP>> => {
  try {
    const res = await BaseService({
      url: `/driver/default-payment-credentials`,
      method: 'GET',
    });
    return createStandardResponse<IDefaultPSP>(res.status, res.data.data, res.data.message, null);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const createDatatransInvoice = async (
  data: IPaymentInvoiceInput,
): Promise<StandardResponse<IPaymentInvoice>> => {
  try {
    const res = await BaseService({
      url: '/driver/payment-datatrans/create/invoice',
      method: 'POST',
      data,
    });
    return createStandardResponse<IPaymentInvoice>(res.status, res.data.data, res.data.message, null);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getCharityDetails = async (data): Promise<StandardResponse<ICreditCharityData>> => {
  try {
    const res = await BaseService({
      url: 'driver/get-charity-details',
      method: 'POST',
      data,
    });
    return createStandardResponse<ICreditCharityData>(res.status, res.data.data, res.data.message, null);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getChargingSession = async (): Promise<StandardResponse<IChargingSession>> => {
  try {
    const res = await BaseService({
      url: `/driver/current-session`,
      method: 'GET',
    });
    return createStandardResponse<IChargingSession>(res.status, res.data.data, res.data.message, null);
  } catch (e) {
    return Promise.reject(e);
  }
};
